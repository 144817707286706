import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit"
import * as Sentry from "@sentry/sveltekit"
import { PUBLIC_ENVIRONMENT, PUBLIC_RELEASE } from "$env/static/public"

Sentry.init({
  dsn: "https://30c6461d68907d93034a3fb26d771840@o4507624861794304.ingest.de.sentry.io/4507624867102800",
  enabled: import.meta.env.PROD && !import.meta.env.VITE_TESTING,
  environment: PUBLIC_ENVIRONMENT,
  release: PUBLIC_RELEASE,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
